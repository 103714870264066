import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MikeWiseContact from "../components/mikewisecontactus"
import LynneMarlorContact from "../components/lynnemarlorcontactus"
import SamNathanContact from "../components/samnathancontactus"
import HeatherLoweContact from "../components/heatherlowecontactus"
import EricRouxContact from "../components/ericrouxcontactus"
import JohnHargravesContact from "../components/johnhargravescontactus"
import DougMehneContact from "../components/dougmehnecontactus"
import AJDeboleContact from "../components/ajdebolecontactus"


export default function WhoTemplate() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [isOverlayVisibleLM, setIsOverlayVisibleLM] = useState(false)
  const [isOverlayVisibleSN, setIsOverlayVisibleSN] = useState(false)
  const [isOverlayVisibleHL, setIsOverlayVisibleHL] = useState(false)
  const [isOverlayVisibleER, setIsOverlayVisibleER] = useState(false)
  const [isOverlayVisibleJH, setIsOverlayVisibleJH] = useState(false)
  const [isOverlayVisibleDM, setIsOverlayVisibleDM] = useState(false)
  const [isOverlayVisibleAD, setIsOverlayVisibleAD] = useState(false)

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }		
  const handleToggleOverlayLM = () => {
    setIsOverlayVisibleLM(!isOverlayVisibleLM)
  }		
  const handleToggleOverlaySN = () => {
    setIsOverlayVisibleSN(!isOverlayVisibleSN)
  }		
  const handleToggleOverlayHL = () => {
    setIsOverlayVisibleHL(!isOverlayVisibleHL)
  }	
  const handleToggleOverlayER = () => {
    setIsOverlayVisibleER(!isOverlayVisibleER)
  }		
  const handleToggleOverlayJH = () => {
    setIsOverlayVisibleJH(!isOverlayVisibleJH)
  }		
  const handleToggleOverlayDM = () => {
    setIsOverlayVisibleDM(!isOverlayVisibleDM)
  }		
  const handleToggleOverlayAD = () => {
    setIsOverlayVisibleAD(!isOverlayVisibleAD)
  }		

  return (
    <Layout breadcrumbs={["Resources"]}>
          <div class="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Blockchain Resources</div>
      <p className="about-us-carousel">From blockchain best practices. to regulatory research and legislative tools, the BBA publishes ongoing research and thought leadership to help our members build blockchain "the right way."</p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1703177000/mxm6byfxu8ztspgaflev.jpg" class="group-3" />
    </div>  
    <div className="container-noflex">
      <div className="container-resources">
        <div className="column-resources"><h2>The Massachusetts Legislator’s Toolkit for Blockchain Technology</h2><p>Our free resource for Massachusetts lawmakers to learn about the potential benefits and risks of blockchain technology, as well as the policy options available to them.</p><p>Created alongside the Chamber of Digital Commerce in Washington, D.C., our toolkit is a comprehensive guide for policy makers containing:</p><ul><li>An overview of blockchain technology</li><li>The potential benefits of blockchain technology</li><li>The potential risks of blockchain technology</li><li>Policy options for lawmakers</li><li>Case studies of how blockchain technology is being used</li><li>A glossary of terms</li></ul><p>The toolkit is a valuable resource for lawmakers and anyone else who wants to learn more about this exciting technology.</p><p className="btn-resources"><div className="mission-btn-resources"><a href="../BBA - Massachusetts Legislator's Toolkit for Blockchain Technology 101121.pdf" title="Download" target="_blank"><div className="become-a-member">Download</div></a></div></p></div>
        <div className="column-resources-img"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1702130667/resources/%D0%97%D0%BD%D1%96%D0%BC%D0%BE%D0%BA_%D0%B5%D0%BA%D1%80%D0%B0%D0%BD%D0%B0_2023-11-10_%D0%BE_09.45_1_kgmqml.png" /></div>
      </div>
      <div className="container-resources-flip">
      <div className="column-resources-img"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1702134396/resources/%D0%97%D0%BD%D1%96%D0%BC%D0%BE%D0%BA_%D0%B5%D0%BA%D1%80%D0%B0%D0%BD%D0%B0_2023-11-10_%D0%BE_09.45_2_h0taui.png" /></div>        
        <div className="column-resources"><h2>Best Practices for Building Web3 Communities</h2><p>Our guide to establishing a robust online community for the success of blockchain or crypto projects. Through insights from leaders in successful Web3 projects, the guide offers strategies to cultivate a dedicated community that resonates with the project's vision and aids its growth. In this info-packed guide, you’ll learn:</p><ul><li>Central Role of Communities: Why a strong online community is indispensable – and how to build them.</li><li>Voice of Experience: What successful Web3 leaders have learned – and what they wished they’d known before starting out.</li><li>Passion Drives Projects: How to build a passionate community around your project, and sustain them for the long term.</li></ul><p>This guide is essential for blockchain enthusiasts, crypto project leaders, and anyone aiming to build and nurture a Web3 community effectively.</p><p className="btn-resources"><div className="mission-btn-resources"><a href="../BBA - Best Practices for Building Web3 Communities.pdf" title="Download" target="_blank"><div className="become-a-member">Download</div></a></div></p></div>
</div>        
      <div className="container-resources">
      <div className="column-resources"><h2>Best Practices for Community Meetups</h2><p>Your go-to guide for creating Web3 communities. Based on hosting thousands of participants at our BBA meetups, this guide offers in-depth insights and strategies for conducting successful community events:</p><ul><li>In-Person vs. Online: How to balance between in-person and virtual meetups to cater to different audiences.</li><li>Location Choices: Where to hold your event (even for those on a budget).</li><li>Promotion: How to use Meetup.com and social media for effective event promotion.</li><li>Event Structure: Best practices on everything from “How long should it last?” to “How much food should we order?”</li><li>Speaker Selection: Tips on landing all-star speakers to boost attendance and engagement.</li><li>Costs: A complete budget breakdown, and how to find sponsors to help underwrite your costs.</li></ul><p>This guide is a must-read for community managers, event organizers, and anyone keen on building and nurturing active, engaged Web3 communities.</p><p className="btn-resources"><div className="mission-btn-resources"><a href="../BBA - Best Practices for Meetups.pdf" title="Download" target="_blank"><div className="become-a-member">Download</div></a></div></p></div>       
        <div className="column-resources-img"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1702923966/resources/ppm2zuerkmgudlwuyuep.png" /></div>
     </div>
    
    </div>  
     
      </Layout>
  )
}